import React from 'react'
import { CreatedBy } from '@accodeing/gatsby-theme-heimr'

import siteMetaData from '../query/siteMetaData'

export default () => {
  const { email } = siteMetaData()
  return (
    <oma-grid-row
      contentAlignedWithGrid
      fullWidth
      class='row row--with-theme-color row--with-top-margin footer__row'
    >
      <footer className='footer'>
        <div className='footer__links'>
          <a className='footer__link' href='/cookies'>
            Cookies
          </a>
          <a className='footer__link' href='/personuppgiftspolicy'>
            Personuppgiftpolicy
          </a>
          <oma-link to={email} class='footer__link' />
        </div>
        <CreatedBy />
      </footer>
    </oma-grid-row>
  )
}
