import React from 'react'

import { Layout as HeimrLayout } from '@accodeing/gatsby-theme-heimr'

import 'style/index.scss'

export default ({ children, pageTitle, siteImage }) => (
  <HeimrLayout
    siteImageRelativePath={siteImage && siteImage.childImageSharp.fluid.src}
    pageTitle={pageTitle}
    omaComponents={['grid@0.5.0', 'grid-row@0.5.0', 'link@0.5.0']}
  >
    <oma-grid>{children}</oma-grid>
  </HeimrLayout>
)
