import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import BackgroundImage from 'gatsby-background-image'
import { HamburgerButton, Menu } from '@accodeing/gatsby-theme-heimr'

import siteMetaData from '../query/siteMetaData'

export default ({ fluidImage, imgStyle = { objectPosition: '50% 50%' } }) => {
  const [menuOpen, setMenuOpened] = useState(false)
  const { phone } = siteMetaData()

  return (
    <>
      <Helmet>
        <script
          defer
          src='https://kit.fontawesome.com/15a4391064.js'
          crossorigin='anonymous'
        ></script>
      </Helmet>
      <oma-grid-row fullWidth class='row header'>
        <BackgroundImage
          fluid={fluidImage}
          className='header__image'
          style={{ backgroundPosition: imgStyle.objectPosition }}
        >
          <HamburgerButton
            open={menuOpen}
            onOpen={() => setMenuOpened(true)}
            onClose={() => setMenuOpened(false)}
          />
          <div className='header__info-box'>
            <p className='header__info-box-times'>
              Telefontid vardagar 8.00-9.00
            </p>
            <p className='header__info-box-number'>
              <span className='fa-stack header__info-box-icon'>
                <i className='far fa-circle fa-stack-2x'></i>
                <i className='fas fa-phone fa-stack-1x'></i>
              </span>
              <oma-link to={phone} class='header__info-box-number-link' />
            </p>
          </div>
          <Menu open={menuOpen} className='menu--mobile' />
        </BackgroundImage>
      </oma-grid-row>
      <oma-grid-row contentAlignedWithGrid fullWidth class='menu row--bordered'>
        <Menu open={menuOpen} />
      </oma-grid-row>
    </>
  )
}
